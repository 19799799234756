@import url('https://fonts.googleapis.com/css?family=Open+Sans:300i,400,400i,600,700|Poppins:200,200i,300,300i,400,500,500i,600,700,800,900&display=swap');

// fonts
$font1:  'Poppins', sans-serif;
$font2:  'Open Sans', sans-serif;
// fonts-size

$heading-color:#2e2e2e;
$gray-color: #bebebe;
$gray-color-2: #bdbdbd;

$theme-color: #2e2e2e;
$theme-color2: #222222;
$theme-color3: #BC9321;
$theme-color4: #ff3500;

$gray-color3:#5c5c5c;
$white_color:#fff;



$font_1: #909092;
$font_2: #66666f;
$font_3: #7f7f7f;
$font_4: #8a8a8a;
$font_5: #999999;
$font_6: #666666;
$font_7: #5c5c5c;
$border_color: #fdcb9e;
$footer_bg: #303030;
$sidebar_bg: #fbf9ff;

$medium_device : 'only screen and (min-width: 992px) and (max-width: 1200px)';
$tab_device:'only screen and (min-width: 768px) and (max-width: 991px)';
$large_mobile: 'only screen and (min-width: 576px) and (max-width: 767px)';
$tab:'(max-width: 991px)';
$small_mobile:'(max-width: 576px)';
$xs_mobile:'(max-width: 420px)';
$sm_mobile:'only screen and (min-width: 421px) and (max-width: 575px)';
$big_screen:'only screen and (min-width: 1200px) and (max-width: 1440px)';
$extra_big_screen: 'only screen and (min-width: 1200px) and (max-width: 3640px)';

// ,,,,,,,,,,,
$btn_bg: #eb592d;
$btn_hover: #f5790b;
$section_bg: #f7f7f7;
$section_bg_1: #454545;
$heading_color: #2F69B1;
$heading_color2: #ff8b23;

$large_device_2:'(min-width: 1501px) and (max-width: 1700px)';
/* Normal desktop :1200px. */
$large_device:'(min-width: 1200px) and (max-width: 1500px)';

/* Normal desktop :992px. */
$mid_device:'(min-width: 992px) and (max-width: 1200px)';

/* Tablet desktop :768px. */
$tablet_device:'(min-width: 768px) and (max-width: 991px)';

/* small mobile :320px. */
$mobile_device:'(max-width: 767px)';

/* Large Mobile :480px. */
$large_mobile:'only screen and (min-width: 480px) and (max-width: 767px)';
