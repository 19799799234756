@import '../../variable.scss';
.footer-container{
	color: #fff;
}
.widget-lpinfo{
	margin-bottom: 2rem;
}
/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  display: none;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
}

.back-to-top i {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background: $heading_color;
  color: #fff;
  transition: all 0.4s;
}

.back-to-top i:hover {
  background: $heading_color !important;
  color: #fff !important;
}

a:hover { 
    text-decoration: none !important; 
} 
